<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <TableRefunds/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import TableRefunds from "@/views/Ecommerce/Refunds/Widgets/TableRefunds";

export default {
  name: "Refunds-List",
  components: {
    TableRefunds
  },
  activated() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },

};
</script>
