<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="orders"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalOrders"
          loading-text="Loading orders.."
          show-select
          disable-filtering
          :no-data-text="noData"
          item-key="id"
          v-model="selectedItems"
        >
          <template v-slot:top v-if="authorized">
            <v-toolbar flat height="110">
              <v-row>
                <v-col lg="3" md="2">
                  <!-- Date Picker -->
                  <DatePicker
                    name="orders-date"
                    @selected-dates="getOrders"
                    :initial_dates="[dates.before, dates.after]"
                  />
                </v-col>
                <v-col lg="3" md="3" xl="4">
                  <!-- Search bar -->
                  <v-text-field
                    v-on:keyup.enter="getOrders()"
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    append-icon="fas fa-search"
                    dense
                    flat
                    filled
                    clearable
                    solo
                    height="20"
                    v-model="search"
                    type="text"
                    placeholder="Order ID(s)"
                    hint="example: 2159XX,222XXX,231XXX"
                    @click:clear="getOrders()"
                    @click:append="getOrders()"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size=".875rem"
                        class="align-center"
                        >fas fa-cart-arrow-down mb-2
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-layout row justify-end>
                  <v-flex shrink align-self-center>
                    <!-- Drop down payment type -->
                    <FilterDropDown
                      :selector-items="paymentMethodSelector"
                      :default="paymentMethodSelector[0]"
                      @clicked="select_payment_method"
                      :disabled="loading"
                    ></FilterDropDown>
                  </v-flex>
                  <v-flex shrink align-self-center>
                    <!-- Drop down type -->
                    <FilterDropDown
                      :selector-items="typeSelector"
                      :default="typeSelector[1]"
                      @clicked="select_type"
                      :disabled="loading"
                    ></FilterDropDown>
                  </v-flex>
                  <v-flex shrink align-self-center>
                    <!-- Drop down status -->
                    <FilterDropDown
                      :selector-items="statusSelector"
                      :default="statusSelector[0]"
                      @clicked="select_status"
                      :disabled="loading"
                    ></FilterDropDown>
                  </v-flex>
                  <v-flex shrink align-self-center mr-4>
                    <!-- Export menu -->
                    <v-menu transition="slide-y-transition" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!isProcessing || loading || isDigital"
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-uppercase
                            btn-default btn-outline-default
                            py-2
                            px-6
                            me-2
                          "
                          color="transparent"
                          small
                        >
                          <v-icon>ni ni-archive-2 me-1</v-icon>

                          Export ({{ selectedItems.length || totalOrders }})
                          <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          class="list-item-hover-active py-2"
                          @click="
                            export_file({
                              name: 'labels',
                              icon: 'fas fa-dolly-flatbed',
                            })
                          "
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              <v-icon>fas fa-dolly-flatbed me-1</v-icon>

                              Shipping Labels
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          class="list-item-hover-active pb-2"
                          @click="
                            export_file({ name: 'forms', icon: 'fas fa-copy' })
                          "
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              <v-icon>fas fa-copy me-1</v-icon>

                              Shipping Forms
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <hr />
                        <v-list-item
                          class="list-item-hover-active pb-2"
                          color="success"
                          @click="selectedItems = []"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              <v-icon class="unselect"
                                >fas fa-times me-1</v-icon
                              >

                              <span class="unselect"> Unselect all </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-row>
            </v-toolbar>
            <v-toolbar flat height="20" class="mb-5 refresh-toolbar">
              <v-row>
                <v-col class="text-left">
                  <RefreshButton
                    :loading="loading"
                    @click="getOrders(null)"
                  ></RefreshButton>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:item.id="{ item }">
            <div class="d-flex align-center">
              <router-link
                class="my-2 text-md text-body font-weight-bold"
                :to="{
                  name: 'Details',
                  path: '/orders/' + item.id,
                  params: { temp_order: item, orderid: item.id },
                }"
                >#{{ item.id }}</router-link
              >
              <div v-if="item.parent_id !== 0" class="ml-1">
                <span
                  class="my-2 text-sm text-body font-weight-bold font-italic"
                >
                  Sub order of
                </span>
                <router-link
                  class="my-2 text-md text-body font-weight-bold"
                  :to="{
                    name: 'Details',
                    path: '/orders/' + item.parent_id,
                    params: { orderid: item.parent_id },
                  }"
                  >#{{ item.parent_id }}</router-link
                >
              </div>
            </div>
          </template>

          <template v-slot:item.date_created="{ item }">
            <span
              class="my-2 text-xs text-body font-weight-bold"
              v-if="item.date_created"
              >{{ moment(item.date_created).format("YYYY-MM-DD h:mm:ss a") }}
              <br />
              ({{ moment(item.date_created).fromNow() }})</span
            >
          </template>

          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center">
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="
                  item.status == 'processing'
                    ? 'border-success'
                    : 'border-default'
                "
              >
                <v-icon
                  size="12"
                  :class="
                    item.status == 'processing' ? 'text-success' : 'text-dark'
                  "
                >
                  {{ item.status | order_status_icon }}
                </v-icon>
              </v-btn>
              <span
                class="text-body text-md font-weight-bold"
                style="text-transform: capitalize"
                >{{ item.status }}</span
              >
            </div>
          </template>

          <template v-slot:item.shipping.state="{ item }">
            <span
              class="my-2 text-md text-body font-weight-bold"
              v-if="item.shipping.state"
              >{{ states[item.shipping.state] || item.shipping.state }}</span
            >
            <span v-else class="text-body">-</span>
          </template>

          <template v-slot:item.shipping.city="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.shipping.city || "-"
            }}</span>
          </template>

          <template v-slot:item.total="{ item }">
            <span class="my-2 text-md text-body font-weight-bold"
              >${{ item.total }}</span
            >
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-md">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="16"
              max="2"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Number of items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              v-show="!loading"
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#00AD4D"
              v-model="page"
              @next="page++"
              @previous="page--"
              :length="totalPages"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import states from "@/util/States";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import FilterDropDown from "@/components/Selectors/FilterDropDown";
import { EventBus } from "@/event-bus";
import Task from "@/util/Task";
import SelectorItem from "@/util/SelectorItem";

import ORDER_STATUS from "@/util/OrderStatus";
import RefreshButton from "@/components/RefreshButton";
export default {
  name: "table-orders",
  components: { RefreshButton, DatePicker, FilterDropDown },
  data() {
    return {
      moment,
      states,
      page: 1,
      pageCount: 0,
      itemsPerPage: 16,
      totalPages: 0,
      totalOrders: 0,
      loading: false,
      pagination: {},
      dates: {
        after: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
        before: moment().endOf("day").format("YYYY-MM-DD HH:mm"),
      },
      orders: [],
      selectedItems: [],
      authorized: true,
      statusSelector: [],
      status: [],
      selected_status: ["processing"],
      order_types: ["physical"],
      payment_types: ["any"],
      current_status: [],
      search: "",
      params: new URLSearchParams(),
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      paymentMethodSelector: [
                new SelectorItem({
          title: "All",
          value: "any",
          icon: "fas fa-cart-plus",
        }),
        new SelectorItem({
          title: "Card",
          value: "card",
          icon: "fas fa-credit-card",
        }),
        new SelectorItem({
          title: "COD",
          value: "cod",
          icon: "fas fa-hand-holding-usd",
        }),
                new SelectorItem({
          title: "Credit",
          value: "credit",
          icon: "fas fa-wallet",
        }),

      ],
      typeSelector: [
              new SelectorItem({
          title: "All",
          value: "any",
          icon: "fas fa-cart-plus",
        }),
        new SelectorItem({
          title: "Physical",
          value: "physical",
          icon: "fas fa-dolly",
        }),
        new SelectorItem({
          title: "Digital",
          value: "digital",
          icon: "fas fa-wallet",
        }),

      ],
      headers: [
        {
          text: "Order ID",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Date",
          value: "date_created",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "State",
          value: "shipping.state",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "City",
          value: "shipping.city",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  created() {
    for (const [key, value] of Object.entries(ORDER_STATUS)) {
      this.statusSelector.push(
        new SelectorItem({
          title: key,
          value: key,
          icon: value || "",
        })
      );
    }
  },
  methods: {
    select_status(item) {
      this.selected_status = [item.value];
      this.getOrders();
    },
    select_type(item) {
      this.order_types = [item.value];
      this.getOrders();
    },

    select_payment_method(item) {
      this.payment_types = [item.value];
      this.getOrders();
    },

    async getOrders(dates) {
      if (this.loading) return;

      this.loading = true;
      this.orders = [];
      let isSearch = false;
      //token
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      params.append("limit", this.itemsPerPage);

      // Date filter
      if (dates) {
        this.dates = dates;
      }
      let selected_dates = this.dates;

      // Search
      if (this.search) {
        let ids = this.search.split(",").map(Number);
        ids
          .filter((id) => !isNaN(id))
          .forEach((id) => {
            params.append("ids", id);
          });
        isSearch = true;
      }

      // Status and type filters
      if (!isSearch) {
        this.selected_status.forEach((status) => {
          params.append("status", status);
          this.current_status = this.selected_status;
        });
        //Type
        this.order_types.forEach((type) => {
          params.append("types", type);
        });

        //Payment Type
        this.payment_types.forEach((type) => {
          params.append("paymentMethod", type);
        });
      }

      if (selected_dates && !isSearch) {
        params.append(
          "before",
          moment(selected_dates.before).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "after",
          moment(selected_dates.after).format("YYYY-MM-DD HH:mm")
        );
      }
      // Check if param is different and reset page and selected item
      if (this.params.toString() !== params.toString()) {
        this.page = 1;
        this.selectedItems = [];
        this.params = new URLSearchParams(params.toString());
      }

      // Page filter
      params.append("page", this.page);

      try {
        let response = await this.$http({
          method: "get",
          url: "/orders/",
          params: params,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (response.status !== 200) return;
        this.orders = response.data;
        if (
          !("x-pages" in response.headers) &&
          !("x-total" in response.headers)
        )
          return;
        this.totalPages = parseInt(response.headers["x-pages"]);
        this.totalOrders = parseInt(response.headers["x-total"]);
      } catch (error) {
        if (error.response.status === 403) {
          this.authorized = false;
        }
      } finally {
        this.loading = false;
      }
    },

    async export_file({ name, icon }) {
      //Access token
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      // Selected items
      this.selectedItems.forEach((order) => {
        params.append("ids", order.id);
      });

      //Dates
      if (this.dates && this.selectedItems.length === 0) {
        params.append(
          "after",
          moment(this.dates.after).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "before",
          moment(this.dates.before).format("YYYY-MM-DD HH:mm")
        );
      }

      // Status and type filters
      if (!this.search) {
        this.selected_status.forEach((status) => {
          params.append("status", status);
        });
        //Type
        this.order_types.forEach((type) => {
          params.append("types", type);
        });
           //Payment Type
        this.payment_types.forEach((type) => {
          params.append("paymentMethod", type);
        });
      }

      let task = new Task({
        name: "Shipping " + name,
        params: params,
        icon: icon,
      });
      EventBus.$emit("addDownload", task);

      try {
        let response = await this.$http({
          method: "get",
          url: "/shipping/" + name,
          params: params,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (response.status !== 200) {
          task.error = "An error has occurred while sending request to server";
          return;
        }
        if (!("id" in response.data)) {
          task.error = "Server error has occurred";
          return;
        }
        task.task_id = response.data.id;
        EventBus.$emit("watch", task);
      } catch (error) {
        task.completed = true;
        task.error =
          "An error has occurred while sending request to server " +
          error.response.data.detail.msg;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getOrders(this.dates);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    itemsPerPage(val) {
      if (Number.isNaN(val)) return;
      if (val <= 0) return;
      setTimeout(() => {
        this.getOrders();
      }, 1000);
    },
  },
  filters: {
    order_status_icon(status) {
      return ORDER_STATUS[status] || "";
    },
  },

  computed: {
    pages() {
      if (this.items.length === 0) return 0;
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    isProcessing() {
      return this.selected_status.includes("processing");
    },
    isDigital() {
      return this.order_types.includes("digital");
    },
    noData() {
      if (!this.authorized) {
        return "You are not authorized to view this data";
      }
      return "No orders found for the selected criteria";
    },
  },
};
</script>

<style scoped>
.unselect {
  color: red !important;
}

.refresh-toolbar {
  position: absolute;
}
</style>


<style >
table {
  margin-top: 25px !important; /*TODO change to scoped*/
}
</style>