<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="refunds"
          :items-per-page="itemsPerPage"
          :loading="loading"
          :no-data-text="noData"
          :options.sync="pagination"
          :page.sync="page"
          :server-items-length="totalRefunds"
          class="table"
          disable-filtering
          hide-default-footer
          loading-text="Loading refunds.."
          mobile-breakpoint="0"
          show-select
          @page-count="pageCount = $event"
          v-model="selectedItems"
          item-key="refund_id"
        >
          <template v-slot:top v-if="authorized">
            <v-toolbar flat height="130">
              <v-row>
                <v-col lg="3" md="2">
                  <DatePicker
                    :initial_dates="[dates.before, dates.after]"
                    name="refunds-date"
                    @selected-dates="getRefunds"
                  />
                </v-col>
                <v-col lg="3" md="2" xl="3">
                  <v-text-field
                    v-model="search"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    append-icon="fas fa-search"
                    clearable
                    dense
                    filled
                    flat
                    height="20"
                    hide-details
                    hint="example: 2159XX,222XXX,231XXX"
                    placeholder="Order ID"
                    solo
                    type="text"
                    v-on:keyup.enter="getRefunds()"
                    @click:clear="getRefunds()"
                    @click:append="getRefunds()"
                  >
                    <template slot="prepend-inner">
                      <v-icon class="mr-2" color="#adb5bd" size=".875rem"
                        >fas fa-cart-arrow-down mb-2
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-layout justify-end row>
                  <v-flex
                    align-self-center
                    shrink
                    v-if="isEditable(current_status)"
                  >
                    <v-btn
                      :disabled="
                        selectedItems.length === 0 || !canProcessRefunds
                      "
                      elevation="5"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        py-2
                        px-6
                        me-2
                      "
                      color="green"
                      small
                      @click="processSelectedRefunds({ decision: 'APPROVED' })"
                    >
                      <v-icon>fas fa-check mr-2</v-icon>
                      Approve ({{ selectedItems.length }})
                    </v-btn>
                    <v-btn
                      :disabled="
                        selectedItems.length === 0 || !canProcessRefunds
                      "
                      elevation="5"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        py-2
                        px-6
                        me-2
                      "
                      color="red"
                      small
                      @click="processSelectedRefunds({ decision: 'REJECTED' })"
                    >
                      <v-icon>fas fa-times mr-2</v-icon>
                      REJECT ({{ selectedItems.length }})
                    </v-btn>
                  </v-flex>
                  <v-flex align-self-center shrink>
                    <FilterDropDown
                      :default="typeSelector[0]"
                      :disabled="loading"
                      :selector-items="typeSelector"
                      @clicked="select_type"
                    ></FilterDropDown>
                  </v-flex>
                  <v-flex align-self-center shrink>
                    <FilterDropDown
                      :default="statusSelector[0] || countLoading"
                      :disabled="loading"
                      :selector-items="statusSelector"
                      @clicked="select_status"
                      :loading="countLoading"
                    ></FilterDropDown>
                  </v-flex>
                  <v-flex align-self-center mr-4 shrink>
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default btn-outline-default
                        py-2
                        px-6
                        me-2
                      "
                      color="transparent"
                      small
                      @click="export_file"
                    >
                      <v-icon>fas fa-file-download mr-2</v-icon>
                      Report
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-row>
            </v-toolbar>
            <v-toolbar flat height="20" class="mb-5 mt-2">
              <v-row>
                <v-col class="text-left">
                  <RefreshButton
                    :loading="loading"
                    @click="getRefunds(null)"
                  ></RefreshButton>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.refund.id="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-sm text-body font-weight-bold">
                {{ item.refund.id }}
              </span>
            </div>
          </template>
          <template v-slot:item.requested_by.email="{ item }">
            <div class="d-flex align-center">
              <span
                class="
                  my-2
                  text-sm text-body
                  font-weight-bold
                  text-decoration-underline
                "
              >
                {{ item.requested_by.email }}
              </span>
            </div>
          </template>
          <template v-slot:item.refund.destination="{ item }">
            <div class="d-flex align-center">
              <v-icon v-if="item.refund.destination.toLowerCase() === 'bank'">
                fas fa-dollar-sign mr-1
              </v-icon>
              <v-icon
                v-else-if="item.refund.destination.toLowerCase() === 'credit'"
              >
                fas fa-wallet mr-1
              </v-icon>
              <span class="my-2 text-sm text-body font-weight-bold">
                {{ item.refund.destination }}
              </span>
            </div>
          </template>
          <template v-slot:item.refund.qty="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-sm text-body font-weight-bold">
                {{ item.refund.qty }}
              </span>
            </div>
          </template>
          <template v-slot:item.refund.price="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-sm text-body font-weight-bold">
                ${{ item.refund.price | get_3rd_dec }}
              </span>
            </div>
          </template>

          <template v-slot:item.refund.name="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-sm text-body font-weight-bold">
                {{ item.refund.name }}
              </span>
            </div>
          </template>

          <template v-slot:item.refund.order_id="{ item }">
            <div class="d-flex align-center">
              <router-link
                :to="'/orders/' + item.refund.order_id"
                class="my-2 text-sm text-body font-weight-bold"
                >#{{ item.refund.order_id }}</router-link
              >
            </div>
          </template>

          <template v-slot:item.request_date="{ item }">
            <span
              v-if="item.request_date"
              class="my-2 text-sm text-body font-weight-bold"
              >{{ item.request_date | toLocalDate }}
              <br />
              ({{  item.request_date | toRelativeLocal }})
            </span>
          </template>

          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center">
              <v-btn
                :class="
                  item.status == 'processing'
                    ? 'border-success'
                    : 'border-default'
                "
                :ripple="false"
                class="me-2"
                height="20px"
                icon
                outlined
                rounded
                width="20px"
              >
                <v-icon
                  :class="
                    item.status == 'processing' ? 'text-success' : 'text-dark'
                  "
                  size="12"
                >
                  {{ item.status | order_status_icon }}
                </v-icon>
              </v-btn>
              <span
                class="text-body text-sm font-weight-bold"
                style="text-transform: capitalize"
                >{{ item.status }}
                <span v-if="item.status === 'FAILED'"
                  ><code>{{ item.refund_id }}</code> <br />
                  <code>{{ item.refund_process_log }}</code>
                </span>
              </span>
            </div>
          </template>

          <template v-slot:item.refund.type="{ item }">
            <span
              class="my-2 text-sm text-body font-weight-bold text-uppercase"
            >
              <v-icon v-if="item.refund.type.toLowerCase() === 'item'">
                fas fa-box mr-1
              </v-icon>
              <v-icon v-if="item.refund.type.toLowerCase() === 'fee'">
                fas fa-plus-square mr-1
              </v-icon>
              <v-icon v-if="item.refund.type.toLowerCase() === 'shipping'">
                fas fa-truck mr-1
              </v-icon>

              {{ item.refund.type }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            <span class="my-2 text-sm text-body font-weight-bold"
              >${{ (item.refund.qty * item.refund.price) | get_3rd_dec }}</span
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="isEditable(item.status)">
              <v-btn
                class="font-weight-bold mr-2 white--text"
                color="green"
                small
                :disabled="!canProcessRefunds"
                @click="
                  processSingleRefund({
                    refund_id: item.refund_id,
                    decision: 'APPROVED',
                  })
                "
              >
                <v-icon> fas fa-check mr-2 </v-icon>APPROVE
              </v-btn>
              <v-btn
                class="font-weight-bold mr-2 white--text"
                color="red"
                small
                :disabled="!canProcessRefunds"
                @click="
                  processSingleRefund({
                    refund_id: item.refund_id,
                    decision: 'REJECTED',
                  })
                "
              >
                <v-icon> fas fa-times mr-2 </v-icon>REJECT
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 text-md">Items per page:</span>
            <v-text-field
              :value="itemsPerPage"
              background-color="rgba(255,255,255,.9)"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
              color="rgba(0,0,0,.6)"
              hide-details
              light
              max="2"
              min="16"
              outlined
              placeholder="Items per page"
              type="number"
              @input="itemsPerPage = parseInt($event, 10)"
            >
            </v-text-field>
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
              v-show="!loading"
              v-model="page"
              :length="totalPages"
              circle
              class="pagination"
              color="#00AD4D"
              next-icon="fa fa-angle-right"
              prev-icon="fa fa-angle-left"
              @next="page++"
              @previous="page--"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-overlay :value="processingLoading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import states from "@/util/States";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import FilterDropDown from "@/components/Selectors/FilterDropDown";
import { EventBus } from "@/event-bus";
import Task from "@/util/Task";
import SelectorItem from "@/util/SelectorItem";
import REFUND_STATUS from "@/util/RefundStatus";
import swal from "sweetalert2";
import RefreshButton from "@/components/RefreshButton";

export default {
  name: "table-refunds",
  components: { RefreshButton, DatePicker, FilterDropDown },
  data() {
    return {
      states,
      page: 1,
      pageCount: 0,
      itemsPerPage: 16,
      totalPages: 0,
      totalRefunds: 0,
      loading: false,
      processingLoading: false,
      countLoading: false,
      params: new URLSearchParams(),
      moment,
      pagination: {},
      dates: {
        before: moment().endOf("day").format("YYYY-MM-DD HH:mm"),
        after: moment()
          .add(-3, "month")
          .endOf("month")
          .format("YYYY-MM-DD HH:mm"),
      },
      refunds: [],
      selectedItems: [],
      authorized: true,
      statusSelector: [],
      status: [
        "pending",
        "processing",
        "failed",
        "retrying",
        "approved",
        "rejected",
        "in-queue",
        "success",
      ],
      selected_status: ["pending"],
      refund_destinations: [],
      current_status: [],
      search: "",
      canProcessRefunds: false,
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      typeSelector: [
        new SelectorItem({
          title: "ALL",
          value: "",
          icon: "fas fa-funnel-dollar",
        }),
        new SelectorItem({
          title: "CREDIT",
          value: "CREDIT",
          icon: "fas fa-wallet",
        }),
        new SelectorItem({
          title: "BANK",
          value: "BANK",
          icon: "fas fa-dollar-sign",
        }),
      ],

      headers: [
        {
          text: "Order ID",
          align: "start",
          sortable: false,
          value: "refund.order_id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },

        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "refund.type",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Request Date",
          value: "request_date",
          sortable: true,
          width: "200px",

          class: "text-secondary font-weight-bolder opacity-7",
        },

        {
          text: "Item",
          value: "refund.name",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
          align: "start",
          width: "250px",
        },
        {
          text: "Quantity",
          value: "refund.qty",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          width: "10px",
        },
        {
          text: "Price($)",
          value: "refund.price",
          sortable: true,
          width: "50px",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "Total",
          value: "total",
          sortable: true,
          width: "50px",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Destination",
          value: "refund.destination",
          width: "50px",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Requested By",
          value: "requested_by.email",
          sortable: false,
          width: "50px",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Refund Status",
          value: "status",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "250px",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  async created() {
    this.status.forEach((status) => {
      this.statusSelector.push(
        new SelectorItem({ title: status, value: status })
      );
    });

    this.canProcessRefunds = await this.$auth.hasPermissions("process:refunds");
  },
  activated() {
    this.getRefunds(null);
  },
  methods: {
    select_status(item) {
      this.selected_status = [item.value];
      this.getRefunds();
    },
    select_type(item) {
      this.refund_destinations = [item.value];
      this.getRefunds();
    },

    processSingleRefund({ refund_id, decision }) {
      this.processRefund([
        {
          refund_id: refund_id,
          status: decision,
        },
      ]);
    },

    processSelectedRefunds({ decision }) {
      let friendlyDecision = "";
      if (decision === "APPROVED") {
        friendlyDecision = "APPROVE";
      } else if (decision === "REJECTED") {
        friendlyDecision = "REJECT";
      }

      swal
        .fire({
          title: `Process Refunds`,
          text:
            "You are about to " +
            friendlyDecision +
            " " +
            this.selectedItems.length +
            " refunds, confirm to continue",
          showCloseButton: true,
          showDenyButton: true,
          timer: 20000,
          allowEnterKey: false,
          timerProgressBar: true,
          confirmButtonText: "Confirm",
          denyButtonText: "Cancel",
          width: "400px",
          icon: "info",
        })
        .then(async (result) => {
          if (!result.isConfirmed) return;
          let refunds = [];
          this.selectedItems.forEach((refund_selected) => {
            refunds.push({
              refund_id: refund_selected.refund_id,
              status: decision,
            });
          });
          this.selectedItems = [];
          await this.processRefund(refunds);
        });
    },

    async processRefund(data) {
      this.processingLoading = true;
      const accessToken = await this.$auth.getTokenSilently();
      try {
        let response = await this.$http({
          method: "post",
          url: "/refunds/process",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          data: data,
        });

        if (response.status !== 200) {
          console.log(response);
        }
      } catch (error) {
        if (error.response.status === 403) {
          swal.fire({
            title: "Not Authorized",
            text: "You are not authorized to do this action",
            toast: true,
            position: "top-right",
            timer: 10000,
            width: "500px",
            timerProgressBar: true,
            icon: "error",
          });
        } else {
          swal.fire({
            title: "Server error",
            text: "Could not process refund process ",
            toast: true,
            position: "top-right",
            timer: 10000,
            width: "500px",
            timerProgressBar: true,
            icon: "error",
          });
        }
      } finally {
        this.processingLoading = false;
        this.selectedItems = [];
        await this.getRefunds();
      }
    },

    async getStatusCounts() {
      this.countLoading = true;
      const accessToken = await this.$auth.getTokenSilently();
      try {
        let response = await this.$http({
          method: "get",
          url: "/refunds/status/counts",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (response.status !== 200) return;
        let counts = response.data;
        this.statusSelector = [];
        this.status.forEach((status) => {
          this.statusSelector.push(
            new SelectorItem({
              title: status + " (" + counts[status.toUpperCase()] + ")",
              value: status,
              icon: REFUND_STATUS[status] || "",
            })
          );
        });
        console.log(counts);
      } catch (error) {
      } finally {
        this.countLoading = false;
      }
    },

    async getRefunds(dates) {
      if(this.loading) return;
      this.loading = true;
      this.refunds = [];
      let isSearch = false;
      //token
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      // Page filter
      params.append("limit", this.itemsPerPage);
      // Date filter

      if (dates) {
        this.dates = dates;
      }
      let selected_dates = this.dates;
      console.log(selected_dates);
      // Status filter
      this.selected_status.forEach((status) => {
        params.append("status", status.toUpperCase());
        this.current_status = this.selected_status;
      });
      //Type
      this.refund_destinations.forEach((type) => {
        if (type) params.append("destination", type);
      });

      // Search
      if (this.search) {
        let ids = this.search.split(",").map(Number);
        ids
          .filter((id) => !isNaN(id))
          .forEach((id) => {
            params.append("ids", id);
          });
        isSearch = true;
      }

      if (selected_dates && !isSearch) {
        params.append(
          "after",
          moment(selected_dates.after).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "before",
          moment(selected_dates.before).format("YYYY-MM-DD HH:mm")
        );
      }
      // Check if param is different and reset page and selected item
      if (this.params.toString() !== params.toString()) {
        this.page = 1;
        this.selectedItems = [];
        this.params = new URLSearchParams(params.toString());
      }

      // Page filter
      params.append("page", this.page);

      try {
        let response = await this.$http({
          method: "get",
          url: "/refunds/",
          params: params,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.refunds = response.data;
        this.totalPages = parseInt(response.headers["x-pages"]);
        this.totalRefunds = parseInt(response.headers["x-total"]);
      } catch (error) {
        if (error.response.status === 403) {
          this.authorized = false;
        }
      } finally {
        this.loading = false;
      }
      this.getStatusCounts();
    },

    async export_file() {
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      this.selectedItems.forEach((refund) => {
        params.append("ids", refund.refund_id);
      });

      if (this.dates && this.selectedItems.length === 0) {
        params.append(
          "before",
          moment(this.dates.before).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "after",
          moment(this.dates.after).format("YYYY-MM-DD HH:mm")
        );
      }

      //Type
      this.refund_destinations.forEach((type) => {
        if (type) params.append("destination", type);
      });

      let config = {
        method: "get",
        url: "/refunds/report",
        params: params,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      let task = new Task({
        name: "Refunds Report",
        params: params,
        icon: "fas fa-file-csv",
      });
      EventBus.$emit("addDownload", task);
      try {
        let response = await this.$http(config);
        if (response.status !== 200) return;
        task.task_id = response.data.id;
        EventBus.$emit("watch", task);
      } catch (error) {
        task.completed = true;
        task.error =
          "An error has occurred while sending request to server " +
          error.response.data.detail.msg;
      }
    },

    isEditable(status) {
      return (
        status.toString().toUpperCase() === "PENDING" ||
        status.toString().toUpperCase() === "FAILED"
      );
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getRefunds(this.dates);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    itemsPerPage(val) {
      if (Number.isNaN(val)) return;
      if (val <= 0) return;
      setTimeout(() => {
        this.getRefunds();
      }, 1000);
    },
  },
  filters: {
    order_status_icon(status) {
      return REFUND_STATUS[status.toLowerCase()] || "";
    },
    get_3rd_dec(number) {
      if (!number) return 0.0;
      return number.toFixed(2);
    },
    toLocalDate(utc){
      return moment.utc(utc).local().format('YYYY-MM-DD h:mm:ss a');
    },
    toRelativeLocal(utc){
      return moment.utc(utc).local().fromNow();

    }
  },

  computed: {
    pages() {
      if (this.items.length === 0) return 0;
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    noData() {
      if (!this.authorized) {
        return "You are not authorized to view this data";
      }
      return "No refund requests found for the selected criteria";
    },
  },
};
</script>
