<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <table-orders></table-orders>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import TableOrders from "./Widgets/TableOrders";

export default {
  name: "Orders-List",
  components: {
    TableOrders,
  }
};
</script>
