const REFUND_STATUS = {
    'processing': 'fas fa-cogs',
    'failed': 'fas fa-times',
    'retrying': 'fas fa-undo',
    'pending': 'fas fa-stamp',
    'approved': 'fas fa-check',
    'rejected': 'fas fa-ban',
    'in-queue': 'fas fa-step-forward',
    'success': 'fas fa-check-double',
}

export default REFUND_STATUS;
