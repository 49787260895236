<template>
  <div>
    <v-text-field
      v-model="search"
      clearable
      prepend-inner-icon="fas fa-search"
      placeholder="Search refunds"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      disable-pagination
      no-data-text="No refunds issued for this order"
      show-group-by
      :items-per-page="items_per_page"
      hide-default-footer
      dense
      sort-by="request_date"
      sort-desc
    >
      <template v-slot:item.request_date="{ item }">
        {{ item.request_date | toLocalDate }}
        <br />
        ({{ item.request_date | toRelativeLocal }})
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon> {{ item.status | refund_status_icon }} mr-2 </v-icon>
        {{ item.status }}
      </template>

      <template v-slot:item.actions="{ item }">
        <CancelRefundButton :refund_item="item"></CancelRefundButton>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import CancelRefundButton from "@/components/CancelRefundButton";
import REFUND_STATUS from "@/util/RefundStatus";

export default {
  name: "RefundList.vue",
  components: { CancelRefundButton },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      search: "",
      items_per_page: -1,
      headers: [
        {
          text: "Item Line ID ",
          value: "refund.id",
          groupable: true,
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Item Name",
          value: "refund.name",
          sortable: false,
          groupable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Quantity",
          value: "refund.qty",
          sortable: true,
          align: "center",
          groupable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Type",
          value: "refund.type",
          sortable: true,
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Destination",
          value: "refund.destination",
          sortable: true,
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Requested by ",
          value: "requested_by.email",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Request Date",
          value: "request_date",
          sortable: true,
          groupable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          groupable: false,
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },

  filters: {
    humanReadableDate(date) {
      return moment(date).format("LLLL");
    },
    dateFromNow(date) {
      return moment(date).fromNow();
    },
    refund_status_icon(status) {
      return REFUND_STATUS[status.toLowerCase()] || "";
    },
    toLocalDate(utc) {
      return moment.utc(utc).local().format("YYYY-MM-DD h:mm:ss a");
    },
    toRelativeLocal(utc) {
      return moment.utc(utc).local().fromNow();
    },
  },
};
</script>

<style scoped></style>
