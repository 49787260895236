<template>
  <v-btn
    v-if="refund_amount !== 0"
    class="font-weight-bold mr-2 white--text"
    :color="color"
    @click="
      request_refund();
      click;
    "
    :small="small"
    :loading="loading"
    :disabled="disabled"
  >
    <span v-if="disabled">

    <v-icon dark> fas fa-hand-holding-usd mr-2 </v-icon>REFUNDED
    </span>
    <span v-else>

    <v-icon dark> fas fa-undo mr-2 </v-icon> REFUND
    </span>
  </v-btn>
</template>

<script>
import swal from "sweetalert2";
import { EventBus } from "@/event-bus";

export default {
  name: "RefundButton",
  data() {
    return {
      refund_amount: 0,
      loading: false,
    };
  },
  props: {
    color: {
      required: false,
      default() {
        return "green";
      },
    },
    item: {
      required: true,
    },
    small: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      required: true,
    },
    orderID: {
      type: Number,
      required: true,
    },
    preference: {
      type: String,
      required: false,
      default(){
        return "Not known";
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  created() {
    let price = "";
    if (this.item.hasOwnProperty("total")) {
      price = this.item.total;
    } else if (this.item.hasOwnProperty("price")) {
      price = this.item.price;
    }
    if (price === "") {
      return;
    }

    this.refund_amount = parseFloat(price);
  },
  methods: {
    async request_refund() {

      swal
        .fire({
          title: `Refund Request`,
          html: `${this.item.name || this.item.method_title} <br/><br/>  Refund preference: <strong>${this.preference}</strong>`,
          input: "text",
          showCloseButton: true,
          showDenyButton: true,
          buttonsStyling: false,
          timer: 20000,
          allowEnterKey: false,
          timerProgressBar: true,
          returnInputValueOnDeny: true,
          inputPlaceholder: "Refund quantity",
          confirmButtonText: "Credit Refund",
          denyButtonText: "Bank Refund",
          customClass: {
            confirmButton: "sweet-btn-secondary space",
            denyButton: "sweet-btn-danger",
            input: "qty-in",
            footer: "refund-footer",
          },
          width: "400px",
          footer:
            "This is a refund request and will require authorization to be processed.",
        })
        .then(async (result) => {
          if (!result.isConfirmed && !result.isDenied && result.isDismissed)
            return;
          if (result.value === "") {
            return;
          }
          if (!this.isNumeric(result.value)) {
            this.show_error("Quantity should be a number!");
            return;
          }
          let qty = parseInt(result.value);
          if (qty <= 0) {
            this.show_error("Quantity should be a positive number!");
            return;
          }
          let destination = result.isConfirmed ? "CREDIT" : "BANK";

          let id = this.item.id;
          let data = {
            order_id: this.orderID,
            id: id,
            qty: qty,
            type: this.type,
            destination: destination,
          };
          this.loading = true;
          let error_msg = "";
          const accessToken = await this.$auth.getTokenSilently();
          try {
            let response = await this.$http({
              method: "post",
              url: "/refunds/request",
              data: data,
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            });
            if (response.status === 200) {
              EventBus.$emit("refresh_refund", 1);
            }
          } catch (error) {
            if (
              error.response.status === 400 ||
              error.response.status === 422
            ) {
              await swal.fire({
                title: "Invalid request",
                text: error.response.data.detail.msg,
                toast: true,
                position: "center",
                timer: 10000,
                width: "500px",
                timerProgressBar: true,
                icon: "warning",
              });
            } else {
              error_msg = "Server error has occurred, try again later";
              if ("detail" in error.response.data) {
                if ("msg" in error.response.data.detail) {
                  error_msg = error.response.data.detail.msg;
                }
              }
            }
          } finally {
            if (error_msg !== "") {
              swal.fire({
                title: "Server error",
                text: error_msg,
                toast: true,
                position: "top-right",
                timer: 10000,
                width: "500px",
                timerProgressBar: true,
                icon: "error",
              });
            }
            this.loading = false;
          }
        });
    },

    click() {
      this.$emit("click", true);
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
    show_error(text) {
      swal.fire({
        title: "Invalid request",
        text: text,
        toast: true,
        position: "top-end",
        timer: 10000,
        width: "500px",
        timerProgressBar: true,

        icon: "error",
      });
    },
  },
};
</script>
