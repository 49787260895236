const ORDER_STATUS = {
    'processing': 'fas fa-check',
    'pending': 'fas fa-sync',
    'completed': 'fas fa-check-double',
    'on-hold': 'fas fa-stop-circle',
    'prepared': 'fas fa-box',
    'refunded': 'fas fa-undo',
    'cancelled': 'fas fa-ban',
    'trash': 'fas fa-trash',
    'failed': 'fas fa-times',

}

export default ORDER_STATUS;
