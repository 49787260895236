<template>
  <v-container fluid v-if="order">
    <v-row>
      <v-col md="12" sm="12" class="mx-auto">
        <form>
          <v-card class="card-shadow border-radius-xl my-9">
            <div class="px-6 py-6 mt-md-8 mt-6">
              <v-row>
                <v-col lg="5" class="text-start">
                  <v-btn
                    :ripple="false"
                    elevation="0"
                    color="#fff"
                    class="btn-info bg-gradient-primary py-5 px-5"
                    small
                    icon
                    @click="$router.go(-1)"
                  >
                    <v-icon>fas fa-angle-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col lg="7" class="text-end d-flex">
                  <v-btn
                    :ripple="false"
                    elevation="0"
                    color="#fff"
                    class="
                      font-weight-bolder
                      btn-info
                      bg-gradient-secondary
                      mt-auto
                      ms-auto
                      py-5
                      px-6
                      ms-2
                    "
                    small
                    @click="
                      export_file({
                        name: 'labels',
                        icon: 'fas fa-dolly-flatbed',
                      })
                    "
                  >
                    <v-icon>fas fa-dolly-flatbed mr-2</v-icon>
                    SHIPPING LABEL
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    elevation="0"
                    color="#fff"
                    class="
                      font-weight-bolder
                      btn-info
                      bg-gradient-secondary
                      py-5
                      px-6
                      ms-2
                    "
                    small
                    @click="export_file({ name: 'forms', icon: 'fas fa-copy' })"
                  >
                    <v-icon>fas fa-copy mr-2</v-icon>
                    SHIPPING FORM
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="px-6">
              <v-row>
                <v-col md="8" align-self="start">
                  <h2
                    class="text-typo text-h4 font-weight-bold mb-2"
                    style="text-transform: capitalize"
                  >
                    <span class="text--secondary">Order ID:</span> #{{
                      order.id
                    }}
                  </h2>
                  <h2
                    class="text-typo text-h4 font-weight-bold mb-2"
                    style="text-transform: capitalize"
                  >
                    <span class="text--secondary"> Status:</span>

                    <v-btn
                      :ripple="false"
                      icon
                      outlined
                      rounded
                      width="30px"
                      height="30px"
                      class="ml-2"
                      :class="
                        order.status == 'processing'
                          ? 'border-success'
                          : 'border-default'
                      "
                    >
                      <v-icon
                        size="12"
                        :class="
                          order.status == 'processing'
                            ? 'text-success'
                            : 'text-dark'
                        "
                      >
                        {{ order.status | order_status_icon }}
                      </v-icon>
                    </v-btn>
                    {{ order.status }}
                  </h2>
                   <h2
                    class="text-typo text-h6 mb-2"
                    style="text-transform: capitalize"
                  >
                                      <span class="text--secondary font-weight-bold mr-1">Payment Method:</span>

                  
                 <span class="text-h7">{{order.payment_method | paymentMethodFilter}}  </span>
                   </h2>
                  <h2
                    class="text-typo text-h6 mb-2"
                    style="text-transform: capitalize"
                  >
                    {{ order.date_created | humanReadableDate }} <br />
                    ({{ order.date_created | dateFromNow }})
                  </h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2" align-self="start">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Shipping Address
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p class="text-secondary">
                          <span class="font-weight-bold text--secondary"
                            >State:</span
                          >
                          {{ order.shipping.state | getFullState }} ({{
                            order.shipping.state
                          }})<br />
                          <span class="font-weight-bold text--secondary"
                            >City</span
                          >
                          {{ order.shipping.city }}<br />
                          <span class="font-weight-bold text--secondary"
                            >Address 1:</span
                          >
                          {{ order.shipping.address_1 }} <br />
                          <span class="font-weight-bold text--secondary"
                            >Address 2:</span
                          >
                          {{ order.shipping.address_2 }} <br />
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col md="8" align-self="start">
                  <h6 class="text-typo text-h6 font-weight-bold">
                    Refund Preference:
                  </h6>
                  <p class="text-secondary">
                    {{ order.additional.refund_options || "Not Known" }}
                  </p>
                </v-col>
              </v-row>
            </div>
            <div class="px-6 py-1">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    clearable
                    prepend-inner-icon="fas fa-search"
                    placeholder="Search items"
                  ></v-text-field>
                  <v-data-table
                    :headers="headers"
                    :items="order.line_items"
                    :search="search"
                    no-data-text="No items in this order"
                    no-results-text="No items were found"
                    dense
                  >
                    <template v-slot:header.refunded_qty="{ header }">
                      <v-icon small class="mr-1" color="green"
                        >fas fa-check-double</v-icon
                      >{{ header.text }}
                    </template>
                    <template v-slot:header.refund_pending="{ header }">
                      <v-icon small class="mr-1" color="orange"
                        >fas fa-stamp</v-icon
                      >{{ header.text }}
                    </template>

                    <template v-slot:item.refund_options="{ item }">
                      <div class="my-1" v-if="issueRefundsPermission">
                        <RefundButton
                            :preference="order.additional.refund_options"

                            :item="item"
                          type="item"
                          :orderID="order.id"
                          small
                          :disabled="
                            get_refunds_qty({
                              id: item.id,
                              status: 'SUCCESS',
                            }) === item.quantity
                          "
                        ></RefundButton>
                      </div>
                    </template>
                    <template v-slot:item.price="{ item }">
                      ${{ item.price | get_3rd_dec }}
                    </template>
                    <template v-slot:item.total="{ item }">
                      ${{ (item.quantity * item.price) | get_3rd_dec }}
                    </template>
                    <template v-slot:item.quantity="{ item }" class="qty">
                      {{ item.quantity }}
                    </template>

                    <template v-slot:item.refund_pending="{ item }" class="qty">
                      {{ get_refunds_qty({ id: item.id, status: "PENDING" }) }}
                    </template>
                    <template v-slot:item.refunded_qty="{ item }" class="qty">
                      {{ get_refunds_qty({ id: item.id, status: "SUCCESS" }) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-col lg="5" md="7" class="mt-auto ms-auto">
                <v-row
                  class="mt-md-5 mt-4 text-left"
                  v-if="order.shipping_lines"
                >
                  <v-col md="6" class="py-0"> </v-col>
                  <v-col md="6" class="py-0">
                    <h6
                      class="
                        text-secondary text-h6
                        font-weight-bold
                        mb-0
                        text-right
                      "
                    >
                      <span v-if="order.shipping_lines.length === 0"
                        >No shipping fees</span
                      >
                      <span v-else>Shipping</span>
                    </h6>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-md-5 mt-4 text-md-end text-start"
                  v-for="shipping in order.shipping_lines"
                >
                  <v-col md="6" class="py-0">
                    <h6 class="text-secondary text-h6 font-weight-bold mb-0">
                      {{ shipping.method_title }} ({{
                        getVendorName(shipping)
                      }})
                    </h6>

                    <RefundButton
                        v-if="issueRefundsPermission"
                      :item="shipping"
                      type="shipping"
                      :orderID="order.id"
                        :preference="order.additional.refund_options"
                      small
                    ></RefundButton>
                  </v-col>

                  <v-col md="6" class="py-0">
                    <h6 class="text-dark text-h6 font-weight-bold mb-0">
                      ${{ shipping.total }}
                    </h6>
                    <hr />
                  </v-col>
                </v-row>

                <v-row class="mt-md-5 mt-4 text-left" v-if="order.fee_lines">
                  <v-col md="6" class="py-0"> </v-col>
                  <v-col md="6" class="py-0">
                    <h6
                      class="
                        text-secondary text-h6
                        font-weight-bold
                        mb-0
                        text-right
                      "
                    >
                      <span v-if="order.fee_lines.length === 0">No fees</span>
                      <span v-else>Fees</span>
                    </h6>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-md-5 mt-4 text-md-end text-start"
                  v-for="fee in order.fee_lines"
                >
                  <v-col md="6" class="py-0">
                    <h6 class="text-secondary text-h6 font-weight-bold mb-0">
                      {{ fee.name }}

                      <RefundButton
                          v-if="issueRefundsPermission"
                          :preference="order.additional.refund_options"

                          :item="fee"
                        type="fee"
                        :orderID="order.id"
                        small
                      ></RefundButton>
                    </h6>
                  </v-col>

                  <v-col md="6" class="py-0">
                    <h6 class="text-dark text-h6 font-weight-bold mb-0">
                      ${{ fee.total }}
                    </h6>
                    <hr />
                  </v-col>
                </v-row>

                <v-row class="mt-md-5 mt-4 text-md-end text-start">
                  <v-col md="6" class="py-0">
                    <h6 class="text-secondary text-h6 font-weight-bold mb-0">
                      Total:
                    </h6>
                  </v-col>

                  <v-col md="6" class="py-0">
                    <h6 class="text-dark text-h6 font-weight-bold mb-0">
                      ${{ order.total }}
                    </h6>
                  </v-col>
                </v-row>
              </v-col>
              <v-row> </v-row>
            </div>
            <div class="px-6 py-6 mt-md-8 mt-6" v-if="readRefundsPermission">
              <h3 class="text-typo text-h5 font-weight-bold mb-3">Refunds</h3>

              <v-row>
                <v-col >
                  <RefundList :items="refunds"></RefundList>
                </v-col>
              </v-row>
            </div>
            <div class="px-6 py-6 mt-md-8 mt-6" v-else>

            <code>You are not authorized to read refunds</code>
            </div>
          </v-card>
        </form>
      </v-col>
    </v-row>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import moment from "moment";
import states from "@/util/States";
import RefundList from "@/views/Ecommerce/Orders/Widgets/RefundList";
import swal from "sweetalert2";
import { EventBus } from "@/event-bus";
import Task from "@/util/Task";
import RefundButton from "@/components/RefundButton";
import ORDER_STATUS from "@/util/OrderStatus";

export default {
  name: "OrderDetails",
  components: { RefundButton, RefundList },
  props: ["temp_order"],
  data() {
    return {
      search: "",
      order: null,
      shop: "",
      loading: false,
      refunds: [],
      readRefundsPermission: false,
      issueRefundsPermission: false,
      headers: [
        {
          text: "Item Line ID",
          align: "start",
          value: "id",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Product ID",
          value: "product_id",
          sortable: false,
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "SKU",
          value: "sku",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Price ($)",
          value: "price",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "Quantity",
          value: "quantity",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          sortable: true,
        },
        {
          text: "Total ($)",
          value: "total",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          sortable: true,
        },
        {
          text: "Pending Refunds",
          value: "refund_pending",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "Refunded Quantity",
          name: "refunded_qty",
          value: "refunded_qty",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
        {
          text: "Actions",
          sortable: false,
          value: "refund_options",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
      ],
    };
  },

  async activated() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loading = true;
    console.log(this.temp_order)
    if (this.temp_order) {
      this.order = this.temp_order;
    } else {
      await this.getCurrentOrder();
    }
    EventBus.$on("refresh_refund", async () => {
      this.refunds = await this.get_refunds();
    });
    this.refunds = await this.get_refunds();
    this.loading = false;
    this.readRefundsPermission = await this.$auth.hasPermissions("read:refunds")
    this.issueRefundsPermission = await this.$auth.hasPermissions("request:refunds")
  },
  methods: {
    async getCurrentOrder() {
      const accessToken = await this.$auth.getTokenSilently();
      let config = {
        method: "get",
        url: "/orders/" + this.$route.params.orderid,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      let request = await this.$http(config).catch((error) => {
        if (error.response.status !== 200) {
          this.$router.push({ name: "main" });
          return;
        }
      });
      if (request.status === 200) {
        this.order = request.data;
      }
    },

    async get_refunds() {
      this.loading = true;
      const accessToken = await this.$auth.getTokenSilently();
      let config = {
        method: "get",
        url: "/refunds/" + this.order.id,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      try {
        let request = await this.$http(config);
        this.loading = false;

        if (request.status === 200) {
          return request.data;
        } else {
          swal.fire({
            title: "Server error",
            text: "Could not retrieve refund data",
            toast: true,
            position: "top-right",
            timer: 10000,
            width: "500px",
            timerProgressBar: true,
            icon: "warning",
          });
        }

      } catch (error) {
        this.loading = false;
      }

      return [];
    },

    getVendorName(shipping) {
      if (this.order === null) return "Not Known";
      let store = {};
      store.shop_name = "";
      shipping.meta_data
        .filter((meta) => meta.key === "seller_id")
        .forEach((meta) => {
          store = this.order.stores
            .filter((store) => store.id.toString() === meta.value)
            .reduce((store) => {
              return store;
            });
        });
      return store.shop_name;
    },
    get_refunds_qty({ id, status }) {
      let sum = 0;
      this.refunds
        .filter((item) => item.refund.id === id && item.status === status)
        .forEach((item) => {
          sum = sum + item.refund.qty;
        });
      return sum;
    },

    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },

    async export_file({ name, icon }) {
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      params.append("ids", this.order.id);

      let config = {
        method: "get",
        url: "/shipping/" + name,
        params: params,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      let task = new Task({
        name: "Shipping " + name,
        params: params,
        icon: icon,
      });
      EventBus.$emit("addDownload", task);
      this.$http(config)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          task.task_id = data.id;
          EventBus.$emit("watch", task);
        })
        .catch((error) => {
          task.error =
            "An error has occurred while sending request to server " +
            error.response.data.detail.msg;
        });
    },
  },
  filters: {
    humanReadableDate(date) {
      return moment(date).format("LLLL");
    },
    dateFromNow(date) {
      return moment(date).fromNow();
    },

    getFullState(shortForm) {
      return states[shortForm] || shortForm;
    },
    get_3rd_dec(number) {
      return number.toFixed(2);
    },
    order_status_icon(status) {
      return ORDER_STATUS[status] || "";
    },
    paymentMethodFilter(paymentMethod) {
      if(paymentMethod === "stripe"){
        return "Card";
      }else if(paymentMethod === "cod"){
        return "Cash on Delivery (COD)"
      }else if(paymentMethod === ""){
        return "Wallet - Former WPAY"
      }
      return paymentMethod
    },
  },
  computed: {},
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}

.qty {
  width: 50px;
}
</style>

<style>
.space {
  margin-right: 10px;
}

.qty-in {
  display: inline-flex !important;
  flex-direction: column;
  align-items: flex-start !important;
  text-align: center;
}

.refund-footer {
  text-align: center;
}
</style>
