<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="loading"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-default btn-outline-default
          py-2
          px-6
          me-2
        "
        color="transparent"
        small
      >
        <span v-for="selected in selectedItems" >
          <v-icon v-if="selected.icon">{{ selected.icon }} me-1</v-icon>

          {{ selected.title }}
        </span>
        <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
      </v-btn>
    </template>

    <v-list class="py-0">
      <v-list-item
        class="list-item-hover-active py-2"
        v-for="item in selectorItems"
        :key="item.value"
        @click="select(item)"
      >
        <v-list-item-content class="pa-0" style="text-transform: capitalize">
          <v-list-item-title
            class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
          >
            <v-icon v-if="item.icon">{{ item.icon }} me-1</v-icon>

            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import SelectorItem from "@/util/SelectorItem";

export default {
  name: "FilterDropDown",
  props: {
    selectorItems: {
      required: true,
      type: Array,
    },
    default: {
      required: true,
      type: SelectorItem,
    },
    loading:{
      required: false,
      type: Boolean,
      default(){
        return false;
      }
    },
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      title: "",
      selectedItems: [],
    };
  },
  created() {
    if (this.default) {
      this.selectedItems = [this.default];
    }
  },
  methods: {
    select(item) {
      this.selectedItems = [item];
      this.$emit("clicked", item);
    },
  },
  watch:{
    default(val){
      console.log(val)
      this.selectedItems.filter(selector => selector.value === val.value).forEach(selector =>{
        this.selectedItems[this.selectedItems.indexOf(selector)] = val;
      })
    }
  }
};
</script>

<style scoped></style>
