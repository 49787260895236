<template>
  <v-btn
      v-if="refund_item.status === 'PENDING'"
      class="font-weight-bold mr-2"
      color="red"
      dark
      :loading="loading"
      @click="cancel({'refund_id':refund_item.refund_id})"
  >
    <v-icon dark>
      fas fa-trash-alt mr-2
    </v-icon>Cancel
  </v-btn>
</template>

<script>
import {EventBus} from "@/event-bus";
import swal from "sweetalert2";

export default {
  name: "CancelRefundButton",
  data(){
    return{
      loading:false
    }
  },
  props:{
    refund_item:{
      required: true
    }
  },
  methods:{
    async cancel({refund_id}){
      this.loading = true;
      const accessToken = await this.$auth.getTokenSilently();
      let config = {
        method: "delete",
        url: "/refunds/"+refund_id,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      let cancelRequest = await this.$http(config)
      console.log(cancelRequest)
      if(cancelRequest.status === 200){
        EventBus.$emit('refresh_refund', 1);
      }else{
        await swal.fire({
          title: 'Error',
          text: 'Error deleting request',
          toast: true,
          position: 'top-end',
          timer: 10000,
          width: "500px",
          timerProgressBar: true,
          icon: 'error'
        })
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>