import uniqueString from 'unique-string';

class SelectorItem {
    constructor({title, action,icon,id,value}) {
        this.title = title;
        this.action = action;
        this.value = value;
        this.icon = icon;
        this.id = uniqueString() || id;
    }

}

export default SelectorItem ;
